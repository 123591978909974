import React, { Component } from 'react';
import './NameGenerator.scss';

class NameGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: 1,
      max: 10,
      number: 1
    }
  }

  componentDidMount() {
    this.setState({
      number: this.generateNumber(this.state.min, this.state.max)
    })
  }

  minChange = (event) => {
    this.setState({
      min: event.target.value
    })
  }

  maxChange = (event) => {
    this.setState({
      max: event.target.value
    })
  }

  generateNumber = (min, max) => {
    const names = ['Liya', 'Aida', 'Nyala', 'Mazaa', 'Kia', 'Dani', 'Nuru', 'Aisha', 'Abena', 'Zuri', 'Azeb', 'Ikram', 'Aster', 'Eden', 'Ledia', 'Selam', 'Maya', 'Bethlehem', 'Edna', 'Jamila', 'Faizah', 'Habiba', 'Makda', 'Mariam', 'Sarem', 'Behati', 'Fana', 'Halima', 'Kadida', 'Izara', 'Abel', 'Yonas', 'Aaron', 'Fuad', 'Desta', 'Abel', 'Bayu', 'Tariku', 'Haile', 'Negasi', 'Abraham', 'Hakim', 'Kaleb', 'Alemayehu', 'Selassie', 'Salim', 'Negus', 'Taye', 'Fatuma', 'Elilta', 'Elfnesh', 'Jemal', 'Ephrem', 'Emanuel', 'Genet', 'Zenebe', 'Daniel', 'Michael', 'Naod', 'Nuralem', 'Yodit', 'Hana', 'Helen', 'Arsema', 'Yohana', 'Tsega', 'Semhal', 'Semhar', 'Sara', 'Rahel', 'Tsion', 'Eleni', 'Zina', 'Tsehaye', 'Bemnet', 'Hilina', 'Edom', 'Keneni', 'Lubaba', 'Tsedenia', 'Hirut', 'Timnit', 'Muna', 'Hermela', 'Selamawit', 'Hiwot', 'Fasika', 'Senait', 'Meseret', 'Tigist', 'Martha', 'Tihitina', 'Nebiyat', 'Abigail', 'Meron', 'Melat', 'Seble', 'Mihret', 'Meskerem', 'Kiros', 'Nahosenay', 'Tewodros', 'Haftom', 'Andom', 'Fetsum', 'Mebrahtu', 'Zenebe', 'Bereket', 'Naya', 'Medhin', 'Kaleab', 'Tsigereda', 'Gelila', 'Kibrom', 'Henok', 'Yemane', 'Solomon', 'Zufan', 'Araya', 'Saba', 'Awet', 'Meaza', 'Esayas', 'Teklit', 'Mulu', 'Natna', 'Mehari', 'Tewolde', 'Birhan', 'Birhanu', 'Estifanos', 'Habtom', 'Bisrat', 'Qedamawit', 'Netsanet', 'Abenezer', 'Axum', 'Mahlet', 'Mizan', 'Merhawit', 'Hagos', 'Soliana', 'Shewit', 'Rahwa', 'Mekdes', 'Haben', 'Afomia', 'Hadas'];
    // console.log(names);
    // console.log(names[Math.floor(Math.random() * names.length)]);

    // return Math.floor(Math.random() * (max - min + 1) + min)
    return names[Math.floor(Math.random() * names.length)]
  }

  getInputs = () => {
    if (this.state.min > this.state.max) {
      const minTemp = this.state.min
      const maxTemp = this.state.max
      this.setState({
          min: maxTemp,
          max: minTemp
        }, () =>
        this.setState({
          number: this.generateNumber(this.state.min, this.state.max)
        })
      );
    } else {
      this.setState({
        number: this.generateNumber(this.state.min, this.state.max)
      })
    }
  }

  render() {
    return (
      <div id="generator">
        <div id="title">Ethiopian Name Generator</div>
        <p id="rNum">{ this.state.number }</p>
        <div id="inputContainer">
          <div id="headers">
          </div>
          <div id="inputs">
            <input id="generate" type="submit" value="Generate Name" onClick={ this.getInputs }/>
          </div>
        </div>
      </div>
    );
  }
}

export default NameGenerator;