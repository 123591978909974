import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReactGA from 'react-ga';
import NameGenerator from './components/NameGenerator/NameGenerator';
ReactGA.initialize('UA-155365109-1');

function App() {
  if (process.env.NODE_ENV !== 'development') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return (
    <div className="App">
      < NameGenerator / >
    </div>
  );
}

export default App;
